import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "./layout"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Head from "../components/head"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  MetaFlex,
  Published,
  Tags,
  TimeToRead,
} from "../components/blogList/Posts"
import { Tag } from "../components/projectListing/ProjectContent"
import { mdxComponents } from "../components/mdx/mdx"

export const query = graphql`
  query($slug: String!) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        coverImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        tags
      }
      body
      timeToRead
    }
  }
`
// fields {
// socialcard
// }

function BlogPage(props) {
  const post = props.data.mdx
  const tagsArrayLength = post.frontmatter.tags.length
  // const socialImage =  post.fields.socialcard;
  return (
    <Layout>
      <Head title={post.frontmatter.title} />
      <header>
        <h1 className="title_header">{post.frontmatter.title}</h1>
        <MetaFlex justifyContent="flex-start">
          <Published>{post.frontmatter.date}</Published>
          <TimeToRead>{`${post.timeToRead} minute${
            post.timeToRead > 1 ? "s" : ""
          }`}</TimeToRead>
        </MetaFlex>
        {post.frontmatter.tags && (
          <Tags>
            {post.frontmatter.tags.map((tag, index) => {
              const comma = index !== tagsArrayLength - 1 ? "," : ""
              return (
                <Tag key={`${post.id}-${tag}`}>
                  <Link to={`/tags/${tag}`}>
                    {tag}
                    {comma}
                  </Link>
                </Tag>
              )
            })}
          </Tags>
        )}
      </header>
      <GatsbyImage
        className="full-bleed"
        image={post.frontmatter.coverImage.childImageSharp.gatsbyImageData}
        alt={`Cover Image for the post ${post.frontmatter.title}`}
      />
      <MDXProvider components={mdxComponents}>
        <MDXRenderer>{post.body}</MDXRenderer>
      </MDXProvider>
    </Layout>
  )
}

export default BlogPage
